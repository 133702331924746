export default {
    "labels-show-results": "Show %{total} Results",
    "reset-all": "Reset All",
    "close": "Close",
    "email-example": "Must be like: name@domain.com",
    "shop-rating": "rating",
    "evaluate-first": "Evaluate first",
    "The privacy policy text is here": "The privacy policy text is here.",
    "Black Friday": "Black Friday",
    "Succesfully added alert": "Succesfully added alert",
    "You will be informed with your selection when the price reaches the one you set": "You will be informed with your selection when the price reaches the one you set.",
    "Go to account": "Go to account",
    "Mens": "Mens",
    "Womens": "Womens",
    "Sale%": "Sale%",
    "Top Trends": "Top Trends",
    "Brands": "Brands",
    "Shops": "Sneaker Shops",
    "Limited Editions": "Limited Editions",
    "We are proud of our shops": "We’re proud of our shops",
    "View all our shops": "View all our shops",
    "Search": "Search",
    "Find": "Find",
    "Buy": "Buy",
    "Trending right now": "Trending right now",
    "All trending sneakers": "All trending Sneakers",
    "Download our app": "Download our app",
    "Terms & privacy": "Terms & privacy",
    "Legal & contact": "Legal & contact",
    "Sale": "Sale",
    "Want to receive updates?": "Want to receive updates?",
    "Sign up": "Sign up",
    "All rights reserved": "All rights reserved",
    "Made in Vienna": "Made in Vienna",
    "Sneaker Search Engine": "Sneaker Search Engine",
    "Search over 100 sneaker shops at once": "Search over 150 sneaker shops at once.",
    "Added to your favorite sneakers": "Added to your favorite sneakers",
    "Remove": "Remove",
    "Sign in": "Sign in",
    "My account": "My account",
    "My favorite pairs": "My favorite pairs",
    "My notifications": "My notifications",
    "Logout": "Logout",
    "Coupon codes": "Coupon codes",
    "Products in our shop": "Products in our shop",
    "Go to our shop": "Go to our shop",
    "All shops": "All shops",
    "All countries": "All countries",
    "All Brands": "All Brands",
    "All brands": "All brands",
    "Online only": "Online only",
    "Premium shops": "Premium shops",
    "Sizes": "Sizes",
    "Go to shop": "Go to shop",
    "Unisex": "Unisex",
    "Kids": "Kids",
    "Show filters": "Show filters",
    "Close filters": "Close filters",
    "Ship in my country": "Ship in my country",
    "Free shipping": "Free shipping",
    "Shipping included": "Shipping included",
    "Free Shipping": "Free Shipping",
    "Save this pair": "Save this pair",
    "Notify me about prices": "Notify me about prices",
    "Size": "Size",
    "Select size(s)": "Select size(s)",
    "Selected sizes": "Selected sizes",
    "Premium shop": "Premium shop",
    "Notify about price": "Notify about price",
    "Remove price alert": "Remove price alert",
    "Found at": "Found at",
    "Price from": "Price from",
    "You might also like": "You might also like",
    "Filter": "Filter",
    "Vegan": "Vegan",
    "Immediately available": "Immediately available",
    "Gender": "Gender",
    "men": "Men",
    "women": "Women",
    "Brand": "Brand",
    "Color": "Color",
    "Sort by": "Sort by",
    "Results": "Results",
    "Sneaker Search": "Sneaker Search",
    "Accept Terms & Conditions": "Accept %{linkOpen}Terms & Conditions%{linkClose}",
    "Your email": "Your email",
    "Forgot Password?": "Forgot Password?",
    "Please check you E-Mail and confirm your account": "Please check you E-Mail and confirm your account.",
    "Register new account": "Register new account",
    "Already have an account?": "Already have an account?",
    "Hey!": "Hey!",
    "Welcome Back": "Welcome Back",
    "Signing up is absolutely": "Signing up is absolutely",
    "Free!": "Free!",
    "Perfect!": "Perfect!",
    "Never miss a pair again": "Never miss a pair again.",
    "Your email is being verified": "Your email is being verified...",
    "Your email is verified!": "Your email is verified!",
    "Reset Password": "Reset Password",
    "We have e-mailed your password reset link!": "We have e-mailed your password reset link!",
    "Your password has changed!": "Your password has changed!",
    "Sorry, something went wrong": "Sorry, something went wrong.",
    "Please enter your email": "Please enter your email",
    "Please enter at least 6 characters": "Please enter at least 6 characters",
    "Please enter your password": "Please enter your password",
    "Do you really want to remove": "Do you really want to remove",
    "your price alert?": "your price alert?",
    "Yes Please": "Yes Please",
    "Your name here": "Your name here",
    "Personal": "Personal",
    "E-Mail": "E-Mail",
    "Name": "Name",
    "Last name": "Last name",
    "Country": "Country",
    "Favorite Currency": "Favorite Currency",
    "Change Password": "Change Password",
    "Current Password": "Current Password",
    "New Password": "New Password",
    "Repeat New Password": "Repeat New Password",
    "Newsletter": "Newsletter",
    "Get all your news and infos about upcoming sneaker releases and discounts in just one monthly email": "Get all your news and infos about upcoming sneaker releases and discounts in just one monthly email.",
    "Subscribe Newsletter": "Subscribe Newsletter",
    "Save Changes": "Save Changes",
    "My favorite sneakers": "My favorite sneakers",
    "Price Alert set for": "Price Alert set for",
    "until": "until",
    "Delete Alert": "Delete Alert",
    "Minimum price alert": "Minimum price alert",
    "Desired minimum value": "Desired maximum value",
    "Timeout": "Timeout",
    "After the specified time, the alert will be deleted": "After the specified time, the alert will be deleted",
    "month": "month",
    "months": "months",
    "E-mail address": "E-mail address",
    "Set alert": "Set alert",
    "Austria": "Austria",
    "France": "France",
    "Germany": "Germany",
    "Terms Of Service & Privacy Policy": "Terms Of Service & Privacy Policy",
    "Register": "Register",
    "From": "from",
    "countries": {
        "official": {
            "IC": "Canary Islands",
            "CY": "Republic of Cyprus",
            "ER": "State of Eritrea",
            "LR": "Republic of Liberia",
            "BM": "Bermuda",
            "VA": "Vatican City State",
            "CK": "Cook Islands",
            "SO": "Federal Republic of Somalia",
            "ZM": "Republic of Zambia",
            "VE": "Bolivarian Republic of Venezuela",
            "TM": "Turkmenistan",
            "AL": "Republic of Albania",
            "HR": "Republic of Croatia",
            "GB": "United Kingdom of Great Britain and Northern Ireland",
            "SD": "Republic of the Sudan",
            "TL": "Democratic Republic of Timor-Leste",
            "CG": "Republic of the Congo",
            "AZ": "Republic of Azerbaijan",
            "KE": "Republic of Kenya",
            "AS": "American Samoa",
            "CI": "Republic of Côte d'Ivoire",
            "SN": "Republic of Senegal",
            "VN": "Socialist Republic of Vietnam",
            "SV": "Republic of El Salvador",
            "AF": "Islamic Republic of Afghanistan",
            "MF": "Saint Martin",
            "LV": "Republic of Latvia",
            "GT": "Republic of Guatemala",
            "KW": "State of Kuwait",
            "ST": "Democratic Republic of São Tomé and Príncipe",
            "KG": "Kyrgyz Republic",
            "PL": "Republic of Poland",
            "GU": "Guam",
            "GH": "Republic of Ghana",
            "LT": "Republic of Lithuania",
            "AM": "Republic of Armenia",
            "JE": "Bailiwick of Jersey",
            "GD": "Grenada",
            "TJ": "Republic of Tajikistan",
            "ET": "Federal Democratic Republic of Ethiopia",
            "EH": "Sahrawi Arab Democratic Republic",
            "MA": "Kingdom of Morocco",
            "PR": "Commonwealth of Puerto Rico",
            "CX": "Territory of Christmas Island",
            "NZ": "New Zealand",
            "BN": "Nation of Brunei, Abode of Peace",
            "GF": "Guiana",
            "NU": "Niue",
            "RO": "Romania",
            "SJ": "Svalbard og Jan Mayen",
            "BY": "Republic of Belarus",
            "PA": "Republic of Panama",
            "CM": "Republic of Cameroon",
            "CZ": "Czech Republic",
            "BL": "Collectivity of Saint Barthélemy",
            "GR": "Hellenic Republic",
            "ML": "Republic of Mali",
            "MQ": "Martinique",
            "FR": "French Republic",
            "PK": "Islamic Republic of Pakistan",
            "PE": "Republic of Peru",
            "BB": "Barbados",
            "GL": "Greenland",
            "PM": "Saint Pierre and Miquelon",
            "TD": "Republic of Chad",
            "HU": "Hungary",
            "KM": "Union of the Comoros",
            "BD": "People's Republic of Bangladesh",
            "TK": "Tokelau",
            "FJ": "Republic of Fiji",
            "CN": "People's Republic of China",
            "CO": "Republic of Colombia",
            "VG": "Virgin Islands",
            "DZ": "People's Democratic Republic of Algeria",
            "MV": "Republic of the Maldives",
            "MY": "Malaysia",
            "KY": "Cayman Islands",
            "ES": "Kingdom of Spain",
            "IE": "Republic of Ireland",
            "EE": "Republic of Estonia",
            "PY": "Republic of Paraguay",
            "UY": "Oriental Republic of Uruguay",
            "ZA": "Republic of South Africa",
            "LC": "Saint Lucia",
            "VU": "Republic of Vanuatu",
            "FI": "Republic of Finland",
            "SE": "Kingdom of Sweden",
            "IO": "British Indian Ocean Territory",
            "LB": "Lebanese Republic",
            "US": "United States of America",
            "CL": "Republic of Chile",
            "NF": "Territory of Norfolk Island",
            "MS": "Montserrat",
            "AU": "Commonwealth of Australia",
            "BZ": "Belize",
            "GY": "Co-operative Republic of Guyana",
            "MN": "Mongolia",
            "TV": "Tuvalu",
            "DO": "Dominican Republic",
            "GQ": "Republic of Equatorial Guinea",
            "KN": "Federation of Saint Christopher and Nevis",
            "BO": "Plurinational State of Bolivia",
            "NP": "Federal Democratic Republic of Nepal",
            "TF": "Territory of the French Southern and Antarctic Lands",
            "TW": "Republic of China (Taiwan)",
            "BJ": "Republic of Benin",
            "BG": "Republic of Bulgaria",
            "MD": "Republic of Moldova",
            "IM": "Isle of Man",
            "BT": "Kingdom of Bhutan",
            "KH": "Kingdom of Cambodia",
            "AG": "Antigua and Barbuda",
            "HT": "Republic of Haiti",
            "CV": "Republic of Cabo Verde",
            "GE": "Georgia",
            "BI": "Republic of Burundi",
            "BS": "Commonwealth of the Bahamas",
            "MU": "Republic of Mauritius",
            "LY": "State of Libya",
            "MW": "Republic of Malawi",
            "MX": "United Mexican States",
            "SZ": "Kingdom of Eswatini",
            "PG": "Independent State of Papua New Guinea",
            "DM": "Commonwealth of Dominica",
            "LI": "Principality of Liechtenstein",
            "RU": "Russian Federation",
            "IL": "State of Israel",
            "SG": "Republic of Singapore",
            "UG": "Republic of Uganda",
            "SK": "Slovak Republic",
            "TO": "Kingdom of Tonga",
            "AE": "United Arab Emirates",
            "YT": "Department of Mayotte",
            "SR": "Republic of Suriname",
            "UZ": "Republic of Uzbekistan",
            "SA": "Kingdom of Saudi Arabia",
            "EG": "Arab Republic of Egypt",
            "IT": "Italian Republic",
            "MG": "Republic of Madagascar",
            "NC": "New Caledonia",
            "CA": "Canada",
            "VI": "Virgin Islands of the United States",
            "MH": "Republic of the Marshall Islands",
            "MR": "Islamic Republic of Mauritania",
            "GM": "Republic of the Gambia",
            "TT": "Republic of Trinidad and Tobago",
            "SC": "Republic of Seychelles",
            "JP": "Japan",
            "BR": "Federative Republic of Brazil",
            "SY": "Syrian Arab Republic",
            "SH": "Saint Helena, Ascension and Tristan da Cunha",
            "TZ": "United Republic of Tanzania",
            "AD": "Principality of Andorra",
            "IR": "Islamic Republic of Iran",
            "TG": "Togolese Republic",
            "MT": "Republic of Malta",
            "KR": "Republic of Korea",
            "WS": "Independent State of Samoa",
            "DE": "Federal Republic of Germany",
            "NE": "Republic of Niger",
            "BV": "Bouvet Island",
            "JM": "Jamaica",
            "NI": "Republic of Nicaragua",
            "GN": "Republic of Guinea",
            "AI": "Anguilla",
            "AX": "Åland Islands",
            "BE": "Kingdom of Belgium",
            "PT": "Portuguese Republic",
            "DK": "Kingdom of Denmark",
            "PH": "Republic of the Philippines",
            "WF": "Territory of the Wallis and Futuna Islands",
            "AT": "Republic of Austria",
            "GW": "Republic of Guinea-Bissau",
            "MC": "Principality of Monaco",
            "NA": "Republic of Namibia",
            "UM": "United States Minor Outlying Islands",
            "CR": "Republic of Costa Rica",
            "BA": "Bosnia and Herzegovina",
            "MO": "Macao Special Administrative Region of the People's Republic of China",
            "MZ": "Republic of Mozambique",
            "RE": "Réunion Island",
            "ME": "Montenegro",
            "KP": "Democratic People's Republic of Korea",
            "MP": "Commonwealth of the Northern Mariana Islands",
            "UA": "Ukraine",
            "IQ": "Republic of Iraq",
            "GS": "South Georgia and the South Sandwich Islands",
            "AO": "Republic of Angola",
            "SL": "Republic of Sierra Leone",
            "FM": "Federated States of Micronesia",
            "CU": "Republic of Cuba",
            "TC": "Turks and Caicos Islands",
            "RS": "Republic of Serbia",
            "EC": "Republic of Ecuador",
            "FO": "Faroe Islands",
            "AQ": "Antarctica",
            "PS": "State of Palestine",
            "TR": "Republic of Turkey",
            "KI": "Independent and Sovereign Republic of Kiribati",
            "KZ": "Republic of Kazakhstan",
            "GI": "Gibraltar",
            "IS": "Iceland",
            "PW": "Republic of Palau",
            "QA": "State of Qatar",
            "CH": "Swiss Confederation",
            "PF": "French Polynesia",
            "PN": "Pitcairn Group of Islands",
            "JO": "Hashemite Kingdom of Jordan",
            "MM": "Republic of the Union of Myanmar",
            "TH": "Kingdom of Thailand",
            "BQ": "Bonaire, Sint Eustatius and Saba",
            "AW": "Aruba",
            "GP": "Guadeloupe",
            "NG": "Federal Republic of Nigeria",
            "BH": "Kingdom of Bahrain",
            "LA": "Lao People's Democratic Republic",
            "CC": "Territory of the Cocos (Keeling) Islands",
            "DJ": "Republic of Djibouti",
            "SB": "Solomon Islands",
            "HM": "Heard Island and McDonald Islands",
            "IN": "Republic of India",
            "SM": "Republic of San Marino",
            "LU": "Grand Duchy of Luxembourg",
            "SX": "Sint Maarten",
            "FK": "Falkland Islands",
            "CF": "Central African Republic",
            "BW": "Republic of Botswana",
            "CW": "Country of Curaçao",
            "GG": "Bailiwick of Guernsey",
            "NO": "Kingdom of Norway",
            "GA": "Gabonese Republic",
            "ZW": "Republic of Zimbabwe",
            "LS": "Kingdom of Lesotho",
            "SI": "Republic of Slovenia",
            "AR": "Argentine Republic",
            "BF": "Burkina Faso",
            "YE": "Republic of Yemen",
            "CD": "Democratic Republic of the Congo",
            "OM": "Sultanate of Oman",
            "ID": "Republic of Indonesia",
            "NR": "Republic of Nauru",
            "RW": "Republic of Rwanda",
            "MK": "Republic of North Macedonia",
            "XK": "Republic of Kosovo",
            "NL": "Kingdom of the Netherlands",
            "TN": "Tunisian Republic",
            "SS": "Republic of South Sudan",
            "HN": "Republic of Honduras",
            "VC": "Saint Vincent and the Grenadines",
            "LK": "Democratic Socialist Republic of Sri Lanka",
            "HK": "Hong Kong Special Administrative Region of the People's Republic of China"
        },
        "common": {
            "IC": "Canary Islands",
            "CY": "Cyprus",
            "ER": "Eritrea",
            "LR": "Liberia",
            "BM": "Bermuda",
            "VA": "Vatican City",
            "CK": "Cook Islands",
            "SO": "Somalia",
            "ZM": "Zambia",
            "VE": "Venezuela",
            "TM": "Turkmenistan",
            "AL": "Albania",
            "HR": "Croatia",
            "GB": "United Kingdom",
            "SD": "Sudan",
            "TL": "Timor-Leste",
            "CG": "Republic of the Congo",
            "AZ": "Azerbaijan",
            "KE": "Kenya",
            "AS": "American Samoa",
            "CI": "Ivory Coast",
            "SN": "Senegal",
            "VN": "Vietnam",
            "SV": "El Salvador",
            "AF": "Afghanistan",
            "MF": "Saint Martin",
            "LV": "Latvia",
            "GT": "Guatemala",
            "KW": "Kuwait",
            "ST": "São Tomé and Príncipe",
            "KG": "Kyrgyzstan",
            "PL": "Poland",
            "GU": "Guam",
            "GH": "Ghana",
            "LT": "Lithuania",
            "AM": "Armenia",
            "JE": "Jersey",
            "GD": "Grenada",
            "TJ": "Tajikistan",
            "ET": "Ethiopia",
            "EH": "Western Sahara",
            "MA": "Morocco",
            "PR": "Puerto Rico",
            "CX": "Christmas Island",
            "NZ": "New Zealand",
            "BN": "Brunei",
            "GF": "French Guiana",
            "NU": "Niue",
            "RO": "Romania",
            "SJ": "Svalbard and Jan Mayen",
            "BY": "Belarus",
            "PA": "Panama",
            "CM": "Cameroon",
            "CZ": "Czechia",
            "BL": "Saint Barthélemy",
            "GR": "Greece",
            "ML": "Mali",
            "MQ": "Martinique",
            "FR": "France",
            "PK": "Pakistan",
            "PE": "Peru",
            "BB": "Barbados",
            "GL": "Greenland",
            "PM": "Saint Pierre and Miquelon",
            "TD": "Chad",
            "HU": "Hungary",
            "KM": "Comoros",
            "BD": "Bangladesh",
            "TK": "Tokelau",
            "FJ": "Fiji",
            "CN": "China",
            "CO": "Colombia",
            "VG": "British Virgin Islands",
            "DZ": "Algeria",
            "MV": "Maldives",
            "MY": "Malaysia",
            "KY": "Cayman Islands",
            "ES": "Spain",
            "IE": "Ireland",
            "EE": "Estonia",
            "PY": "Paraguay",
            "UY": "Uruguay",
            "ZA": "South Africa",
            "LC": "Saint Lucia",
            "VU": "Vanuatu",
            "FI": "Finland",
            "SE": "Sweden",
            "IO": "British Indian Ocean Territory",
            "LB": "Lebanon",
            "US": "United States",
            "CL": "Chile",
            "NF": "Norfolk Island",
            "MS": "Montserrat",
            "AU": "Australia",
            "BZ": "Belize",
            "GY": "Guyana",
            "MN": "Mongolia",
            "TV": "Tuvalu",
            "DO": "Dominican Republic",
            "GQ": "Equatorial Guinea",
            "KN": "Saint Kitts and Nevis",
            "BO": "Bolivia",
            "NP": "Nepal",
            "TF": "French Southern and Antarctic Lands",
            "TW": "Taiwan",
            "BJ": "Benin",
            "BG": "Bulgaria",
            "MD": "Moldova",
            "IM": "Isle of Man",
            "BT": "Bhutan",
            "KH": "Cambodia",
            "AG": "Antigua and Barbuda",
            "HT": "Haiti",
            "CV": "Cape Verde",
            "GE": "Georgia",
            "BI": "Burundi",
            "BS": "Bahamas",
            "MU": "Mauritius",
            "LY": "Libya",
            "MW": "Malawi",
            "MX": "Mexico",
            "SZ": "Eswatini",
            "PG": "Papua New Guinea",
            "DM": "Dominica",
            "LI": "Liechtenstein",
            "RU": "Russia",
            "IL": "Israel",
            "SG": "Singapore",
            "UG": "Uganda",
            "SK": "Slovakia",
            "TO": "Tonga",
            "AE": "United Arab Emirates",
            "YT": "Mayotte",
            "SR": "Suriname",
            "UZ": "Uzbekistan",
            "SA": "Saudi Arabia",
            "EG": "Egypt",
            "IT": "Italy",
            "MG": "Madagascar",
            "NC": "New Caledonia",
            "CA": "Canada",
            "VI": "United States Virgin Islands",
            "MH": "Marshall Islands",
            "MR": "Mauritania",
            "GM": "Gambia",
            "TT": "Trinidad and Tobago",
            "SC": "Seychelles",
            "JP": "Japan",
            "BR": "Brazil",
            "SY": "Syria",
            "SH": "Saint Helena, Ascension and Tristan da Cunha",
            "TZ": "Tanzania",
            "AD": "Andorra",
            "IR": "Iran",
            "TG": "Togo",
            "MT": "Malta",
            "KR": "South Korea",
            "WS": "Samoa",
            "DE": "Germany",
            "NE": "Niger",
            "BV": "Bouvet Island",
            "JM": "Jamaica",
            "NI": "Nicaragua",
            "GN": "Guinea",
            "AI": "Anguilla",
            "AX": "Åland Islands",
            "BE": "Belgium",
            "PT": "Portugal",
            "DK": "Denmark",
            "PH": "Philippines",
            "WF": "Wallis and Futuna",
            "AT": "Austria",
            "GW": "Guinea-Bissau",
            "MC": "Monaco",
            "NA": "Namibia",
            "UM": "United States Minor Outlying Islands",
            "CR": "Costa Rica",
            "BA": "Bosnia and Herzegovina",
            "MO": "Macau",
            "MZ": "Mozambique",
            "RE": "Réunion",
            "ME": "Montenegro",
            "KP": "North Korea",
            "MP": "Northern Mariana Islands",
            "UA": "Ukraine",
            "IQ": "Iraq",
            "GS": "South Georgia",
            "AO": "Angola",
            "SL": "Sierra Leone",
            "FM": "Micronesia",
            "CU": "Cuba",
            "TC": "Turks and Caicos Islands",
            "RS": "Serbia",
            "EC": "Ecuador",
            "FO": "Faroe Islands",
            "AQ": "Antarctica",
            "PS": "Palestine",
            "TR": "Turkey",
            "KI": "Kiribati",
            "KZ": "Kazakhstan",
            "GI": "Gibraltar",
            "IS": "Iceland",
            "PW": "Palau",
            "QA": "Qatar",
            "CH": "Switzerland",
            "PF": "French Polynesia",
            "PN": "Pitcairn Islands",
            "JO": "Jordan",
            "MM": "Myanmar",
            "TH": "Thailand",
            "BQ": "Caribbean Netherlands",
            "AW": "Aruba",
            "GP": "Guadeloupe",
            "NG": "Nigeria",
            "BH": "Bahrain",
            "LA": "Laos",
            "CC": "Cocos (Keeling) Islands",
            "DJ": "Djibouti",
            "SB": "Solomon Islands",
            "HM": "Heard Island and McDonald Islands",
            "IN": "India",
            "SM": "San Marino",
            "LU": "Luxembourg",
            "SX": "Sint Maarten",
            "FK": "Falkland Islands",
            "CF": "Central African Republic",
            "BW": "Botswana",
            "CW": "Curaçao",
            "GG": "Guernsey",
            "NO": "Norway",
            "GA": "Gabon",
            "ZW": "Zimbabwe",
            "LS": "Lesotho",
            "SI": "Slovenia",
            "AR": "Argentina",
            "BF": "Burkina Faso",
            "YE": "Yemen",
            "CD": "DR Congo",
            "OM": "Oman",
            "ID": "Indonesia",
            "NR": "Nauru",
            "RW": "Rwanda",
            "MK": "North Macedonia",
            "XK": "Kosovo",
            "NL": "Netherlands",
            "TN": "Tunisia",
            "SS": "South Sudan",
            "HN": "Honduras",
            "VC": "Saint Vincent and the Grenadines",
            "LK": "Sri Lanka",
            "HK": "Hong Kong"
        }
    },
    "Payment options": "Payment options",
    "Categories that are more relevant to your search": "Categories that are more relevant to your search",
    "No sneakers found matching your criteria": "No sneakers found matching your criteria.",
    "Changes saved successfully!": "Changes saved successfully!",
    "Already sent": "Already sent",
    "About Sneakers123": "About Sneakers123",
    "Team": "Team",
    "Story": "Story",
    "Contact": "Contact",
    "Legal": "Legal",
    "Data Protection": "Data Protection",
    "Terms and Conditions": "Terms and Conditions",
    "Imprint": "Imprint",
    "Other": "Other",
    "Press": "Press",
    "Shop Registration": "Shop Registration",
    "Jobs": "Jobs",
    "opportunities": "Sneakers",
    "Find exactly what you want": "Find exactly what you want.",
    "Social Media": "Social Media",
    "placeholders": {
        "homepagesearch": "Find your sneakers",
        "searchbar": "Search %{total} Sneakers"
    },
    "homepage": {
        "search": "Search your new sneakers in over 150 sneaker shops at once!",
        "find": "Find your sneakers fast and easy, at the best price.",
        "buy": "Buy comfortably at selected & tested sneaker shops of your choice.",
        "suggestions": "You might like"
    },
    "sort": {
        "relevant": "Relevant",
        "latest": "Latest",
        "high": "Price High",
        "low": "Price Low"
    },
    "labels": {
        "shipping": "+ %{costs} shipping to %{country}",
        "viewed": "You've viewed %{viewed} of %{total} sneakers",
        "showing": "Showing %{total} sneakers",
        "loadmore": "Load more %{item}"
    },
    "filters": {
        "trending": "Top Trends",
        "sale": "Sale",
        "free_shipping": "Free shipping",
        "available": "Immediately available",
        "vegan": "Vegan",
        "men": "Men's",
        "women": "Women's",
        "black": "black",
        "blue": "blue",
        "white": "white",
        "grey": "grey",
        "lyellow": "light yellow",
        "red": "red",
        "orange": "orange",
        "yellow": "yellow",
        "green": "green",
        "marine": "marine",
        "dark-grey": "dark grey",
        "light-grey": "light grey",
        "pink": "pink",
        "greenyellow": "green yellow",
        "rainbow": "rainbow",
        "kids": "Kids"
    },
    "countries-abbr": {
        "AT": "Austria",
        "DE": "Germany",
        "IT": "Italy",
        "GB": "United Kingdom",
        "FR": "France",
        "ES": "Spain",
        "DK": "Denmark",
        "NL": "Netherlands",
        "PT": "Portugal",
        "US": "United States",
        "SE": "Sweden",
        "BE": "Belgium",
        "CZ": "Czechia",
        "RU": "Russia",
        "CH": "Switzerland",
        "PL": "Poland",
        "LV": "Latvia",
        "EU": "Europe"
    },
    "login-to-load-shops": "Login now and see %{number} more shops",
    "our-brands": "Our brands",
    "register-to-load-shops": "Register now and see %{number} more shops",
    "sneaker-title-general": "%{available} %{trending} %{vegan} %{color} %{gender} %{brands} %{q} sneakers %{sale} %{in_size} %{sizes} %{free_shipping} %{discount_from}",
    "last-time-check": "Last check %{time}",
    "already-rated-text": "You have already rated this store.",
    "only-registered-users-can-rate": "Only registered users can rate",
    "sold-out": "Sold Out",
    "shops": "Shops",
    "We are redirecting you to our partner": "We are redirecting you to our partner.",
    "Available at": "Available at %{count} shops",
    "Last views": "Last views",
    "toggle-intermediate-sizes": "Show / hide intermediate sizes",
    "Shop now at": "Shop now at %{name}",
    "subscribe-newsletter": "Subscribe Newsletter",
    "confirm-unsubscribe": "Are you sure you want to unsubscribe?",
    "We use Cookies": "We use cookies to guarantee the best possible experience. If you continue to browse our site, you accept our cookie policy.",
    "subscription-error": "Something went wrong, please try again later",
    "subscription-success": "You have successfully subscribed to the news!",
    "unsubscription-success": "You have successfully unsubscribed from the news!",
    "product-page-description": "%{fullName} (%{sku}) from %{price} | %{brand} Sneaker at the best price, order easy & secure | Sneakers123.com",
    "copy-code": "Copy code",
    "copied-to-clipboard": "Copied to clipboard",
    "voucher-code-percentage": "Use code %{code} and get %{amount}% extra on the advertised price",
    "voucher-code-currency": "Use Code %{code} and get %{amount} %{currency} OFF",
    "voucher-code-shipping": "Use Code %{code} and get %{amount}% OFF on shipping",
    "Password (min 6 characters)": "Password (min 6 characters)",
    "test1": "test1",
    "Send Reset Link": "Send Reset Link",
    "Login to your account": "Login to your account",
    "Complete Registration": "Complete Registration",
    "english": "English",
    "german": "German",
    "french": "French",
    "spanish": "Spanish",
    "portuguese": "Portuguese",
    "italian": "Italian",
    "dutch": "Dutch",
    "polish": "Polish",
    "russian": "Russian",
    "chinese": "Chinese",
    "go-to-shop": "Go to %{shop}",
    "several-sizes-available": "Available in several sizes",
    "Login with Facebook": "Login with Facebook",
    "Sign up using Facebook": "Sign up using Facebook",
    "Or": "Or",
    "Please enter email to complete registration": "Please enter email to complete registration",
    "Cancel": "Cancel",
    "sub-header-text": "Compare %{totalCount} available models from %{shopsCount} Sneaker Shops starting at %{minPrice}",
    "Show all": "Show all",
    "Offers": "Offer(s)",
    "previous-searches": "Previous-Searches",
    "Clear": "Clear",
    "clear": "Löschen",
    "search": "search",
    "available": "available",
    "Sneakers for Him and Her": "Sneakers for Him and Her",
    "Top Sneaker Brands": "Top Sneaker Brands",
    "Here you will find all current models of the Top Sneaker Brands": "Here you will find all current models of the Top Sneaker Brands",
    "Win Nike Sacai in your size": "Win Nike Sacai in your size",
    "Together with KLEKT we raffle a pair of Nike Sacai of your choice": "Together with KLEKT we raffle a pair of Nike Sacai of your choice",
    "Over 150 of the hottest sneaker stores worldwide": "Over 150 of the hottest sneaker stores worldwide",
    "Thousands of models for men and women at the best price": "Thousands of models for men and women at the best price",
    "The hottest sneakers at a glance": "The hottest sneakers at a glance",
    "Men's Sneakers": "Men's Sneakers",
    "Win a pair of": "Win a pair of",
    "Enter Raffle": "Enter Raffle",
    "All different styles": "All different styles",
    "Women's Sneakers": "Women's Sneakers",
    "The most important brands on Sneakers123": "The most important brands on Sneakers123",
    "Mens Sneakers": "Men's Sneakers",
    "Womens Sneakers": "Women's Sneakers",
    "Find the hottest sneakers quickly and easily": "Find the hottest sneakers quickly and easily",
    "discount-from": "{amount}% or more",
    "product-not-found": "Unfortunately the {product} is no longer available. We listed similar models for you.",
    "category-latest-title": "Latest Models",
    "category-latest-subtitle": "The latest colors of the \"{model_name}\"",
    "category-latest-text_html": "From <b>{shops} partner shops</b> there are <b>{models} different models</b> of the \"{model_name}\", with prices starting <b>from {starting_price}</b>. The prices are compared directly with each other by our price algorithm, so you can save <b>up to {save}%</b>. So you can be sure to always find your desired pair of sneakers at the best price.",
    "category-cheapest-title": "Best Price",
    "category-cheapest-subtitle": "{model_name} available now from {starting_price}",
    "category-price_chart-title": "Price History",
    "category-price_chart-subtitle": "When is the best time to buy?",
    "category-price_chart-text_html": "Not sure if you should wait for a better price or if you should put your new sneakers into the shopping cart right away? Our price history gives you insight into the last months. So you can see with what probability the price will rise or fall in the future. Another possibility is our Sneaker Price Alert.",
    "category-price_alert-title": "{model_name} Price Alert",
    "category-price_alert-subtitle": "Set price alert now and never miss a deal again!",
    "category-price_alert-text_html": "{model_name} at the best price! Register now and set up your Sneaker Price Alert. Just enter the maximum price you want to spend for your model and we will do the rest. As soon as your desired price is reached, we will send you a message and you can buy your sneakers immediately.",
    "category-all_models-title": "All Colors and Sizes",
    "category-all_models-subtitle": "{model_name} available now from {starting_price}",
    "category-shops-title": "Available Shops",
    "category-shops-subtitle": "Which shops have the \"{model_name}\" available?",
    "suggestions-meta-title": "{model_name} easy to find & buy » from {starting_price}",
    "suggestions-meta-description": "Find & buy {model_name} cheap ✅ Starting at just {starting_price} ✅ All {model_name} Colorways ✅ Sneakers123.com",
    "Show more": "Show more",
    "All Models": "All models",
    "Sneaker": "Sneaker",
    "home-header-title": "Sneakers Search Engine<br/>and Price Comparison",
    "Find_exactly_what_you_want": "Find exactly what you want.",
    "homepage_find": "Find your sneakers fast and easy, at the best price.",
    "Here_you_will_find_all_current_models_of_the_Top_Sneaker_Brands": "Here you will find all current models of the Top Sneaker Brands",
    "Find_the_hottest_sneakers_quickly_and_easily": "Find the hottest sneakers quickly and easily",
    "Finde die coolsten Sneakers schnell und unkompliziert": "Find the coolest sneakers quickly and easily",
    "Top Models": "Top Models",
    "Adidas Sneakers": "Adidas Sneakers",
    "size-register-required-text": "To view sizes, please create an account or log in.",
    "to_shop_button": "Go to {shop}",
    "black-friday-subheader": "The best sneaker deals on Black Friday and Cyber Monday! Here you can find all offers, discounts and exclusive coupon codes for Black Friday 2020.",
    "no-size-available": "Visit the store for available sizes",
    "best-prices": "Our Best Prices",
    "sneaker-detail-text": "Sneaker Details",
    "model-text": "Model",
    "style-code-text": "Style Code",
    "brand-text": "Brand",
    "date-added-text": "Date added",
    "in-stock-text": "Only in Stock",
    "filter-sizes-text": "Filter Sizes",
    "price-filter-text": "Price Filter",
    "lowest-price-text": "Lowest Price",
    "higher-price-text": "Higher Price",
    "cashback-text": "CashBack",
    "cashback-partner-text": "cashback partner",
    "available-sizes-text": "Available Sizes",
    "shop-now-text": "Go to Store",
    "sold out": "Sold Out",
    "add-to-list-text": "Add to List",
    "remove-from-list-text": "Remove from List",
    "views-text": "Views",
    "compare-slogan": "Discover unbeatable price ranges, starting from just €{minPrice} upto €{maxPrice}",
    "reseller-text": "Reseller",
    "see-all-prices": "See all prices",
    "coming soon": "coming soon",
    "similar-models-text": "Similar Models",
    "change-model-text": "Change model",
    "shop-now-for-text": "Buy now for",
    "featured-text": "Featured",
    "label": {
        "shop": "Shop",
        "terms": "Terms",
        "seeMorePrices": "See more prices",
        "productDetails": "Product Details",
        "relatedProducts": "Related Products",
        "buyDirect": "Buy Direct",
        "cancel": "Cancel",
        "OK": "OK",
        "checkoutNow": "Checkout Now",
        "languageSettings": "Language Settings",
        "language": "Language",
        "region": "Region",
        "currency": "Currency",
        "more": "More",
        "warning": "Warning",
        "shippingSupportedCountries": "Shipping Service Support Country",
        "currencyCode": {
            "usd": "US Dollar",
            "gbp": "British Pound",
            "eur": "Euro",
            "jpy": "Japanese Yen",
            "krw": "South Korean Won"
        },
        "sneakersDetails": "Sneakers Details",
        "returnsPolicy": "Returns Policy",
        "shippingReturnsPolicy": "Shipping & Returns Policy",
        "orderCancel": "Order Cancel",
        "yourEmail": "Your Email",
        "yourPassword": "Your Password (min 6 characters)",
        "acceptTermsAndConditions": "Accept Terms & Conditions"
    },
    "text": {
        "priceAlertRemoved": "Price alert removed",
        "youMightLike": "You might like",
        "theHottestSneakersAtAGlance": "The hottest sneakers at a glance",
        "pleaseAcceptTermsAndConditions": "Please accept the terms and conditions",
        "signInButton": "Sign In to Your Account",
        "showAndHide": "Show / Hide",
        "sneakersContents": "Sneakers Contents",
        "sneakersContentsSubTitle": "Listen to sneaker-related content news",
        "contentsTitle1": "The History of the Air Max 1",
        "contentsTitle2": "Adidas History: 1949 to Now",
        "contentsTitle3": "History of Air Jordans",
        "contentsTitle4": "History of Yeezy",
        "contentsTitle5": "History of converse",
        "contentsTitle6": "History of Asics",
        "movingToPartnerStore": "Moving to partner store",
        "orderCancelWarning": "Are you sure you want to cancel the order?",
        "orderComplete": {
            "orderDetails": "Order Details",
            "orderNumber": "Order Number",
            "orderStatus": "Order Status",
            "orderItems": "Order Items",
            "priceInformation": "Price Information",
            "priceTotal": "Price Total",
            "shippingFee": "Shipping Fee",
            "taxAndDuties": "Tax & Duties",
            "orderTotal": "Order Total",
            "buyerInformation": "Buyer Information",
            "name": "Name",
            "email": "Email",
            "phone": "Phone",
            "recipientInformation": "Recipient Information",
            "address": "Address"
        },
        "productPage": {
            "shippingAndReturnPolicyDescription1": "our order will be shipped to you directly by {shopName}.",
            "shippingAndReturnPolicyDescription2": "Feel free to initiate the return of your purchased item via {email}.",
            "shippingAndReturnPolicyDescription3": "Our CS team will be happy to assist you throughout the entire process.",
            "shippingAndReturnPolicyDescription4": "Return Period: You have up to 7 days for returns. But return period might be different depending an item you purchase. So please be advised from {email}.",
            "shippingAndReturnPolicyDescription5": "Cost: The customer is accountable for covering all shipping expenses.",
            "shippingAndReturnPolicyDescription6": "Method: Please use the mail service for returns.",
            "shippingAndReturnPolicyDescription7": "See more details on {shopName}",
            "shippingAndReturnPolicyDescription8": "** Since we partner with retailers worldwide, each retailer has its own unique return policy - These policies may differ based on the item you purchase **",
            "youMightAlsoLike": "You might also like",
            "shippingFeeOfThisProduct": "Shipping fee of this product",
            "directDeliveryByShop": "Direct delivery by <span>{shopName}</span>",
            "directDeliveryByShopMessage": "For the latest stock status, size availability and promotions please check directly with <span>{shopName}</span>",
            "discoverUnbeatablePricesRangesStartingFromJustUpTo": "Discover unbeatable prices, ranges starting from just {minPrice} up to {maxPrice}",
            "returnPolicyDescription1": "You are welcome to begin the return process for your purchased item by contacting {email}.",
            "returnPolicyDescription2": "Our customer service team is ready to guide you through each step.",
            "returnPolicyDescription3": "Return Window: Returns are accepted within 7 days, though this may vary based on the purchased item.",
            "returnPolicyDescription4": "For specific details, please consult {email}.",
            "returnPolicyDescription5": "Expenses: Customers are responsible for all return shipping costs.",
            "returnPolicyDescription6": "Procedure: Returns should be sent via postal service.",
            "returnPolicyDescription7": "Discover additional information on SHOP_NAME",
            "returnPolicyDescription8": "As we collaborate with numerous retailers globally, each has a distinct return policy - Note that these policies can vary depending on the specific item you buy"
        },
        "viewAll": "View All",
        "settingTitle": "Change your settings",
        "settingSubtitle": "Choose your region & your preferred language below",
        "shippingWarning": "If you don't live in the shipping country or the product is out of stock, then your order might be canceled.",
        "dontShipToYourCountry": "The country doesn't support shipping.",
        "dontShip": "The country you accessed does not support shipping services.",
        "theCountryYouAreCurrentlyAccessing": "The country you are currently accessing is {country}.",
        "dontShipTo": "The country you accessed does not support shipping services, the country you are currently accessing is {country}.",
        "showMoreSizes": "Show more sizes",
        "deliveryUsOnlyMessage": "The country you are currently connected from is the {country}. We currently only support shipping to the United States. Would you like to proceed?",
        "checkout": {
            "conciergeTitle": "Sneakers123 Concierge",
            "conciergeSubtitle": "Find the best price and deals from our extensive network of thousands of Sneakers partners. We help you save time and money when shopping sneakers online.",
            "paymentInfo": "Payment Info",
            "paymentInfoSubtitle": "By clicking \"Place Order,\" you acknowledge that you have read and agreed to the terms & conditions and privacy policy of Sneakers123, as well as those of each individual retailer from whom you make a purchase.",
            "itemInfo": "Item Info",
            "itemInfoDescription": "These shopping options are offered by <a href=\"{shopLink}\" target=\"_blank\">{shopName}</a>. Please refer to their website for details on shopping and return policies, terms & conditions, and any other relevant policies related to your purchase.",
            "buyerInfo": "Buyer Info",
            "paymentSummary": "Payment Summary",
            "shippingInfo": "Shipping",
            "sameWithBuyer": "Same with buyer",
            "totalIncludingDelivery": "Total including delivery",
            "saveAndContinue": "Save and Continue",
            "deliveryPeriod": "Delivery Period",
            "deliveryPeriodValue": "5-8 days",
            "freeShipping": "Free Shipping",
            "itemTotals": "Item Totals",
            "shippingFee": "Shipping Fee",
            "taxAndDuties": "Est. Tax & Duties",
            "orderTotal": "Order Total",
            "firstName": "First Name",
            "lastName": "Last Name",
            "email": "Email",
            "phone": "Phone",
            "country": "Country",
            "zipCode": "Zip Code",
            "address1": "Address",
            "address2": "Apartment, suite, etc.",
            "isRequired": "{field} is required"
        },
        "checkoutConcierge": "Sneakers123 Concierge",
        "checkoutConciergeSub": "We find the best price, apply available coupons, and confirm your order through one of our 600+ partner stores. Plus, count on us for hassle-free returns and support with any order issues. Your effortless shopping journey begins here.",
        "homePage": {
            "homeStoreReleaseSectionTitle": "Sneakers123 Partner stores",
            "homeStoreReleaseSectionSubTitle": "Over 150 of the hottest sneaker stores worldwide",
            "homeContentsSectionTitle": "Sneakers123 Contents",
            "homeContentsSectionSubTitle": "Listen to sneaker-related content news",
            "homeStorePopularSneakersSectionTitle": "Top popular sneakers by store",
            "homeStorePopularSneakersSectionSubTitle": "Find the hottest sneakers quickly and easily",
            "stores": "Stores",
            "moreStores": "More Stores",
            "homeStylesSectionTitle": "All different styles",
            "homeStylesSectionSubTitle": "Find the hottest sneakers quickly and easily",
            "homeStoreSneakersRankSectionTitle": "Sneakers ranking by store",
            "homeStoreSneakersRankSectionSubTitle": "Top 10 popular sneakers by store this week",
            "homeGenderSectionTitle": "Sneakers for Him and Her",
            "homeGenderSectionSubTitle": "Thousands of models for men and women at the best price",
            "womenSneakers": "Women Sneakers",
            "menSneakers": "Men Sneakers",
            "homeStepSectionTitle": "Sneaker Search Engine & Price Comparison",
            "homeBrandsSectionTitle": "Top Sneaker Brands",
            "homeBrandsSectionSubTitle": "The most important brands on Sneakers123",
            "homeReleasesSectionTitle": "Trending right now",
            "homeReleasesSectionSubTitle": "The hottest sneakers at a glance"
        },
        "languageTitle": "Choose the language you’d like to browse the site in.",
        "countryTitle": "Choose a country or region. This affects the currency you’re billed in, availability of items, price and delivery options.",
        "currencyTitle": "Choose your currency."
    }
}